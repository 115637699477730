<template>
    <div>
        <!-- title -->
        <div class="container-fluid bg-slider-contact-form px-0 position-relative">
            <div class="row position-relative m-0">
                <div class="col-12 py-5 px-0">
                    <div class="container py-5">
                        <div class="row">
                            <div class="col-12 d-flex justify-content-center">
                                <h1 class="d-lg-block d-none text-white mp-rbk mp-font-weight-medium font-size-faq-header-title">FAQ</h1>
                                <h1 class="d-lg-none d-block text-white mp-rbk mp-font-weight-medium font-size-faq-header-title-mobile">FAQ</h1>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-8 offset-lg-2 d-flex justify-content-center">
                                <h2 class="d-lg-block d-none text-white mp-mts font-size-faq-header-subtitle py-4 text-center">
                                    Vielleicht findest du die Antwort auf deine Frage in den FAQs. Wenn nicht, nutze das Kontaktformular und schreibe uns dein Anliegen.
                                </h2>
                                <h2 class="d-lg-none d-block text-white mp-mts font-size-faq-header-subtitle-mobile py-0 text-center">
                                    Vielleicht findest du die Antwort auf deine Frage in den FAQs. Wenn nicht, nutze das Kontaktformular und schreibe uns dein Anliegen.
                                </h2>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12 d-flex justify-content-center">
                                <router-link to="/contact">
                                    <button type="button"
                                            class="btn btn-mp-green font-size-btn-faq-header mp-font-weight-medium py-2 px-5">
                                        Zum Kontakt
                                    </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /title -->

        <!-- content -->
        <div class="container-fluid bg-lightblue" v-if="!isLoading">
            <div class="row">
                <div class="col-12 col-lg-10 offset-lg-1 py-5">
                    <!-- Tabs navs -->
                    <div class="d-flex justify-content-center">
                        <ul class="nav nav-tabs mb-3" id="faq" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button
                                    class="nav-link active faq-tab-title mp-rbk py-2 pe-5 font-size-faq-tab-title"
                                    id="users-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#users"
                                    type="button" role="tab"
                                    aria-controls="users"
                                    aria-selected="true">
                                    FÜR NUTZER
                                </button>
                            </li>
                            <li class="nav-item" role="presentation" v-if="profile.role === 'expert'">
                                <button
                                    class="nav-link faq-tab-title mp-rbk py-2 font-size-faq-tab-title"
                                    id="experts-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#experts"
                                    type="button" role="tab"
                                    aria-controls="experts"
                                    aria-selected="false">
                                    FÜR EXPERTEN
                                </button>
                            </li>
                        </ul>
                    </div>
                    <!-- /Tabs navs -->


                    <!-- Tabs content -->
                    <div class="tab-content" id="faq-content">

                        <!-- faq: users -->
                        <div class="tab-pane fade show active" id="users" role="tabpanel" aria-labelledby="users-tab">
                            <div class="bg-white form-shadow-light form-rounded-left form-rounded-right p-0">
                                <template v-for="f in faqUser">
                                    <!-- question -->
                                    <div class="row" :key="`faq_question_${f.id}`">
                                        <div class="col-12">
                                            <p class="mb-0">
                                                <a class="p-4 faq-question font-size-faq-question mp-rbk mp-font-weight-semi-bold"
                                                   data-bs-toggle="collapse"
                                                   :href="`#faq-user-${f.id}`"
                                                   role="button"
                                                   aria-expanded="false"
                                                   :aria-controls="`faq-user-${f.id}`"
                                                >
                                                    {{ f.question }}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    <!-- answer -->
                                    <div class="row" :key="`faq_answer_${f.id}`">
                                        <div class="col-12">
                                            <div class="collapse" :id="`faq-user-${f.id}`">
                                                <div class="faq-answer font-size-faq-answer mp-qks p-4">
                                                    <p class="mb-0">
                                                        {{ f.answer }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>

                        <!-- faq: experts -->
                        <div class="tab-pane fade" id="experts" role="tabpanel" aria-labelledby="experts-tab" v-if="profile.role === 'expert'">
                            <div class="bg-white form-shadow-light form-rounded-left form-rounded-right p-0">
                                <template v-for="f in faqExpert">
                                    <!-- question -->
                                    <div class="row" :key="`faq_question_${f.id}`">
                                        <div class="col-12">
                                            <p class="mb-0">
                                                <a class="p-4 faq-question font-size-faq-question mp-rbk mp-font-weight-semi-bold"
                                                   data-bs-toggle="collapse"
                                                   :href="`#faq-expert-${f.id}`"
                                                   role="button"
                                                   aria-expanded="false"
                                                   :aria-controls="`faq-expert-${f.id}`"
                                                >
                                                   {{ f.question }}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    <!-- answer -->
                                    <div class="row" :key="`faq_answer_${f.id}`">
                                        <div class="col-12">
                                            <div class="collapse" :id="`faq-expert-${f.id}`">
                                                <div class="faq-answer font-size-faq-answer mp-qks p-4">
                                                    <p class="mb-0">
                                                        {{ f.answer }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /content -->
    </div>
</template>



<script>
    import {mixinBasics} from "../../mixins/basics";
    import {apiCall} from "../../helpers";
    import Configs from "../../configs";

    const api = Configs.restApi.endpoints.map;

    export default {
        name: "Faq",

        metaInfo() {
            return {
                title: 'FAQ',
                meta: [
                    { name: 'FAQ | mergeplate.com' },
                    { title: 'FAQ | mergeplate.com' }
                ].concat(this.buildOpenGraphMetaTags(
                    `FAQ | mergeplate.com`,
                    `FAQ - mergeplate.com - finde deinen Experten auf der Plattform für mentale und physische Gesundheit`,
                    this.$route.path
                ))
            };
        },

        mixins: [
            mixinBasics
        ],

        data() {
            return {
                faq: undefined,
                isLoading: true
            };
        },

        computed: {
            faqUser() {
                return this.faq.filter(f => !f.expert_only);
            },

            faqExpert() {
                return this.faq.filter(f => f.expert_only);
            }
        },

        methods: {
            async getData() {
                let req = api.faq.index;
                let res = await apiCall(req.url, req.method);
                this.faq = res.data;
                this.isLoading = false;
            },
        },

        async created() {
            await this.getData();
        },

        mounted() {
            window.scrollTo(0,0);
        }
    }
</script>


<style scoped>

</style>